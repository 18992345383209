/* You can add global styles to this file, and also import other style files */
@import '../node_modules/ngx-bootstrap/datepicker/bs-datepicker.css';
.grid-view .flex-item {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.grid-view .flex-item .img-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f8f8f8;
  flex: 1 1 auto;
}

.list-view .img-inner {
  max-width: 250px;
}
.list-view .img-fluid {
  max-width: auto;
}
.list-view app-product-box-one {
  width: 100%;
}

.collection-product-wrapper .grid-view .product-box {
  margin-bottom: 0;
  margin-top: 35px;
}

.collection-product-wrapper .list-view .product-box {
  margin-bottom: 0;
  margin-top: 15px;
}

.filter-inp input {
  width: 100%;
  margin-bottom: 10px;
}

.container.my-cont {
  max-width: 100% !important;
}
.container.my-cont .row {
  max-width: 1400px;
  margin: 0 auto;
}

.header-tools {
  top: 0px;
}

.header-tools .top-header .container {
  background-color: #18B270;
}

.service-block svg {
  width: 32px;
  height: 32px;
  margin-right: 20px;
}

.service-block svg path {
  fill: #18B270;
}

.service-block:hover h4 {
  color: #18B270;
}

.container.absolute-bg .row .col-md-4.service-block:nth-child(2) {
  justify-content: start;
}

.collection-collapse-block .collection-collapse-block-content .collection-brand-filter {
  max-height: none;
  overflow: visible;
}

.collection-collapse-block.open .collection-collapse-block-content {
  max-height: none;
  overflow: visible;
}

.filter-inp {
  margin-bottom: 15px;
}

.collection-collapse-block {
  padding-bottom: 0px;
}

.collection-filter-block {
  padding-bottom: 20px;
}

.collection-filter-block .product-service .media .media-body p {
  line-height: 1.4;
}

.center-align-clider .owl-carousel .owl-stage {
  display: flex;
  align-items: center;
}

body::after {
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.8s ease 0s;
  pointer-events: none;
  z-index: 149;
}

.popup-show body::after {
  opacity: 1;
}

.popup {
  position: fixed;
  top: 100px;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 30px 10px;
  transition: visibility 0.8s ease 0s;
  visibility: hidden;
  pointer-events: none;
}

.popup_show {
  z-index: 150;
  visibility: visible;
  overflow: auto;
  pointer-events: auto;
}

.popup_show .popup__content {
  visibility: visible;
  transform: scale(1);
}

.popup__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  flex: 1 1 auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup__content {
  position: relative;
  visibility: hidden;
  transform: scale(0);
  z-index: 1000;
  transition: transform 0.3s ease 0s;
  background-color: #eee;
  padding: 20px;
  width: 100%;
  max-width: 900px;
  border-radius: 20px;
}
.lock .popup__content {
  visibility: visible;
}

.popup__close {
  position: absolute;
  right: 13px;
  top: 10px;
  cursor: pointer;
}

.fieldset {
  display: flex;
  flex-wrap: wrap;
  width: 440px;
}
.fieldset.items-2 {
  width: 440px;
}
.fieldset.items-3 {
  width: 660px;
}
.fieldset.items-100 {
  width: 100%;
}
.fieldset.items-flex {
  display: flex;
  flex-wrap: wrap;
  width: 660px;
}

.input-item {
  min-width: 200px;
  margin: 0 20px 30px 0;
  box-sizing: border-box;
}
.input-item.file {
  width: 100%;
  display: flex;
  padding: 10px;
  border-radius: 5px;
}
.input-item.file.wrappable {
  flex-wrap: wrap;
}
.input-item.file .imgs-container {
  display: flex;
  margin-bottom: 8px;
  max-width: 250px;
}
.input-item.file .imgs-container.full {
  max-width: 100%;
  width: 100%;
}
.input-item.file .img-default {
  height: 72px;
  width: 72px;
  border: 1px solid #e2e7ec;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.input-item.file .img-description {
  margin-left: 16px;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.input-item.file .img-description h3 {
  font-size: 14px;
  color: #637282;
}
.input-item.file .img-description span {
  font-size: 10px;
  color: #a7b1bb;
}
.input-item.file .img-btn {
  height: 40px;
  width: 132px;
  border-radius: 4px;
  background: #18B270;
  color: white;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.input-item.file .img-btn svg {
  margin-right: 4px;
}
.input-item.file .img-upload {
  display: none;
}
.input-item.file .img-clear {
  height: 40px;
  width: 100px;
  border-radius: 4px;
  background: white;
  color: #18B270;
  font-size: 14px;
  display: flex;
  border: 1px solid #ccc;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 8px;
}

.ng-select.ng-select-single .ng-select-container {
  height: 40px;
}

a {
  color: #18B270;
}

@media (max-width: 767px) {
  .container.absolute-bg .row .col-md-4.service-block:nth-child(2) {
    justify-content: center;
    margin-top: 20px;
  }
}
.btn {
  font-family: Mulish !important;
}